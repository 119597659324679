import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { LocationType } from '../../../__generated__/api'
import AuthenticationModal from '../../../domain/reserve/components/AuthenticationModal'
import { authenticationModalOpenAtom, omLightModalAtom } from '../../../state/common/atoms'
import {
  searchServiceModalOpenAtom,
  searchServiceModalAutoOpenAtom,
  landingModalOpenAtom,
} from '../../../state/search/atoms'
import { useSearchEvents } from '../../../xstate/events'
import { useIsUserSelectedNode } from '../../../xstate/selectors'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useOHC } from '../../hooks/useOHC'

interface Props {
  hasLoggedIn: boolean
  hasRedirected?: boolean
}

const OHCModal: React.FC<React.PropsWithChildren<Props>> = ({ hasLoggedIn, hasRedirected }) => {
  const [isModalOpen, setModalOpen] = useRecoilState(authenticationModalOpenAtom)
  const setServiceModalOpen = useSetRecoilState(searchServiceModalOpenAtom)
  const { setSearchLocation: setSelectedLocation } = useSearchEvents()
  const setLandingModalOpen = useSetRecoilState(landingModalOpenAtom)
  const isUserSelectedNode = useIsUserSelectedNode()
  const isModalAutoOpen = useRecoilValue(searchServiceModalAutoOpenAtom)
  const { isOHCSide, ohcAllowedStatus, namedCheckupOfficeCityId, setOHC } = useOHC()
  const sessionStorageKey = 'ohc-service-modal-shown'
  const serviceModalShown = window.sessionStorage?.getItem(sessionStorageKey)
  const omLightModalSessionStorageKey = 'om-light-modal-shown'
  const omLightModalShown = window.sessionStorage?.getItem(omLightModalSessionStorageKey)
  const [omLightModal, setOmLightModal] = useRecoilState(omLightModalAtom)
  const featureFlags = useFeatureFlags()

  useEffect(() => {
    setModalOpen(false)
  }, [setModalOpen])

  useEffect(() => {
    // reset modal state when user logs out
    if (!hasLoggedIn) {
      window.sessionStorage?.setItem(sessionStorageKey, '')
      window.sessionStorage?.setItem(omLightModalSessionStorageKey, '')
    }
  }, [hasLoggedIn])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hasLoggedIn) {
        setModalOpen(true)
      }
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [hasLoggedIn, setModalOpen])

  useEffect(() => {
    if (!isOHCSide || ohcAllowedStatus !== 'allowed' || isModalOpen) {
      return
    }
    // treat serviceModalShown as a ohc-initialization flag. This prevents to reset the location on page refresh
    if (namedCheckupOfficeCityId && serviceModalShown !== 'true') {
      setSelectedLocation([`${LocationType.City}${namedCheckupOfficeCityId}`])
    } else {
      // TODO: Add a warning modal when null
    }
    if (featureFlags?.omlightEnabled) {
      if (omLightModal === 'closed' && omLightModalShown !== 'true') {
        setOmLightModal('open')
        window.sessionStorage?.setItem(omLightModalSessionStorageKey, 'true')
        return
      }
      if (omLightModal === 'closed') {
        if (!serviceModalShown && isModalAutoOpen) {
          window.sessionStorage?.setItem(sessionStorageKey, 'true')
          setServiceModalOpen(true)
        }
        if (serviceModalShown && !isModalAutoOpen) {
          setServiceModalOpen(false)
        }
      }
    } else {
      if (!serviceModalShown && isModalAutoOpen) {
        window.sessionStorage?.setItem(sessionStorageKey, 'true')
        setServiceModalOpen(true)
      }
      if (serviceModalShown && !isModalAutoOpen) {
        setServiceModalOpen(false)
      }
    }
  }, [
    omLightModal,
    setOmLightModal,
    omLightModalShown,
    isModalOpen,
    isOHCSide,
    ohcAllowedStatus,
    namedCheckupOfficeCityId,
    setSelectedLocation,
    setServiceModalOpen,
    isUserSelectedNode,
    serviceModalShown,
    isModalAutoOpen,
    featureFlags,
  ])

  const onAuthenticationClose = () => {
    window.sessionStorage?.setItem('landingModalShown', '')
    setOHC(false)
    setLandingModalOpen(true)
  }

  return (
    <AuthenticationModal
      authCompletedCallback={() => {
        setModalOpen(false)
      }}
      note={hasRedirected ? t('component.ohcModal.redirectedNote') : undefined}
      onClose={onAuthenticationClose}
    />
  )
}

export default OHCModal

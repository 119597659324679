import { Dayjs } from 'dayjs'

import { AppointmentLengthOption, PublicNode } from '../__generated__/api'
import { GroupedFlexibleScheduleOption } from '../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import { FilterOptions, Gender } from '../common/components/FilterOptions/types'
import {
  AppointmentSearchMode,
  defaultSelectedAppointmentTypes,
  defaultSelectedDurations,
  defaultSelectedLanguage,
  defaultSelectedPatientAgeGroup,
  defaultSelectedTimeRanges,
} from '../state/search/atoms'

export type ParsedURL = {
  isOHC: boolean
  isDental: boolean
  nodeId?: string
  node?: PublicNode
  practitionerId?: number
  noLanding?: boolean
  callbackOpen?: boolean
  date?: Dayjs
  location?: string[]
  filterOptions?: Partial<FilterOptions>
  appointmentSearchMode?: AppointmentSearchMode
}

export type SelectedNode = {
  nodeId: string
}

export type SelectedPractitioner = {
  practitionerId: number
  option?: AppointmentLengthOption | GroupedFlexibleScheduleOption
}

export const defaultFilterOptions = {
  appointmentTypes: defaultSelectedAppointmentTypes,
  timeRanges: defaultSelectedTimeRanges,
  gender: Gender.UNSPECIFIED,
  language: defaultSelectedLanguage,
  patientAgeGroup: defaultSelectedPatientAgeGroup,
  durations: defaultSelectedDurations,
}

export enum SearchTargetValue {
  Node = 'node',
  Practitioner = 'practitioner',
}

export type SearchTarget =
  | { id: string; value: SearchTargetValue.Node }
  | { id: number; value: SearchTargetValue.Practitioner }

import { useSelector } from '@xstate/react'
import { Dayjs } from 'dayjs'
import { useCallback } from 'react'

import { AppointmentLengthOption } from '../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { GroupedFlexibleScheduleOption } from '../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import { FilterOptions } from '../common/components/FilterOptions/types'
import { AppointmentSearchMode } from '../state/search/atoms'

import { AppMachineContext } from './providers'
import { defaultFilterOptions, SearchTarget } from './types'

export const useModalEvents = () => {
  const actorRef = AppMachineContext.useActorRef()
  const modalMachineRef = useSelector(actorRef, (snapshot) => snapshot.context.modalMachineRef)

  const openServiceModal = useCallback(
    () => modalMachineRef.send({ type: 'openServiceModal' }),
    [modalMachineRef]
  )

  const selectService = useCallback(
    (value: any) => modalMachineRef.send({ type: 'selectService', value }),
    [modalMachineRef]
  )

  const openPractitionerModal = useCallback(
    () => modalMachineRef.send({ type: 'openPractitionerModal' }),
    [modalMachineRef]
  )

  const selectPractitionerOption = useCallback(
    (value: GroupedFlexibleScheduleOption | AppointmentLengthOption | undefined) =>
      modalMachineRef.send({ type: 'selectPractitionerOption', value }),
    [modalMachineRef]
  )

  const openCallbackModal = useCallback(
    () => modalMachineRef.send({ type: 'openCallbackModal' }),
    [modalMachineRef]
  )

  const searchCallbackAppointments = useCallback(
    () => modalMachineRef.send({ type: 'searchCallbackAppointments' }),
    [modalMachineRef]
  )

  const openInsuranceModal = useCallback(
    () => modalMachineRef.send({ type: 'openInsuranceModal' }),
    [modalMachineRef]
  )

  const openFilterModal = useCallback(
    () => modalMachineRef.send({ type: 'openFilterModal' }),
    [modalMachineRef]
  )

  const openDateModal = useCallback(
    () => modalMachineRef.send({ type: 'openDateModal' }),
    [modalMachineRef]
  )

  const closeModal = useCallback(
    () => modalMachineRef.send({ type: 'closeModal' }),
    [modalMachineRef]
  )

  return {
    openServiceModal,
    selectService,
    openPractitionerModal,
    selectPractitionerOption,
    openCallbackModal,
    searchCallbackAppointments,
    openInsuranceModal,
    openFilterModal,
    openDateModal,
    closeModal,
  }
}

export const useSearchEvents = () => {
  const actorRef = AppMachineContext.useActorRef()
  const searchMachineRef = useSelector(actorRef, (snapshot) => snapshot.context.searchMachineRef)

  const setSearchTarget = useCallback(
    (target: SearchTarget) => searchMachineRef.send({ type: 'setSearchTarget', target }),
    [searchMachineRef]
  )

  const setSearchLocation = useCallback(
    (location: string[]) => searchMachineRef.send({ type: 'setSearchLocation', location }),
    [searchMachineRef]
  )

  const setSearchFilters = useCallback(
    (filters: FilterOptions) => searchMachineRef.send({ type: 'setSearchFilters', filters }),
    [searchMachineRef]
  )

  const resetSearchFilters = useCallback(
    () => searchMachineRef.send({ type: 'setSearchFilters', filters: defaultFilterOptions }),
    [searchMachineRef]
  )

  const setAppointmentSearchMode = useCallback(
    (mode: AppointmentSearchMode) =>
      searchMachineRef.send({ type: 'setAppointmentSearchMode', mode }),
    [searchMachineRef]
  )

  const setSearchDate = useCallback(
    (date: Dayjs) => searchMachineRef.send({ type: 'setSearchDate', date }),
    [searchMachineRef]
  )

  return {
    setSearchTarget,
    setSearchLocation,
    setSearchFilters,
    resetSearchFilters,
    setAppointmentSearchMode,
    setSearchDate,
  }
}

export const useURLEvents = () => {
  const actorRef = AppMachineContext.useActorRef()
  const urlMachineRef = useSelector(actorRef, (snapshot) => snapshot.context.urlMachineRef)

  const setSearchParams = useCallback(
    (urlSearchParams: URLSearchParams) =>
      urlMachineRef.send({ type: 'setSearchParams', urlSearchParams }),
    [urlMachineRef]
  )

  return {
    setSearchParams,
  }
}

import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentInfoResult,
  PublicNode,
  AuthorizedPerson,
  User,
} from '../../../__generated__/api'
import * as Analytics from '../../../common/utils/analytics'
import { useOHC } from '../../hooks/useOHC'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import DelegationUserSelect, {
  ButtonGroupContainer,
  SelectUserOptionButton,
} from '../DelegationUserSelect/DelegationUserSelect'
import { CenteredColumnFlex, MinMaxDiv } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(CenteredColumnFlex)`
  margin: ${scale(2)};
  gap: ${scale(1.5)};

  & > ${Text} {
    text-align: center;
  }
`

interface Props {
  users?: AuthorizedPerson[]
  onSelectUser(user: User): void
  onReserveSelf(): void
  onBack?(): void
  appointmentInfo: AppointmentInfoResult | null
  node: PublicNode | null
  nodePending: boolean
  disableUndefinedSelection?: boolean
}

const UserSelect: React.FC<React.PropsWithChildren<Props>> = ({
  onSelectUser,
  onReserveSelf,
  onBack,
  appointmentInfo,
  node,
  nodePending,
  disableUndefinedSelection,
}) => {
  const { t } = useTranslation()
  const { isOHCSide } = useOHC()
  const [shouldDisplayDelegateView, setshouldDisplayDelegateView] = useState<boolean>(false)

  Analytics.useTrackVerify({
    appointmentInfo,
    node,
    nodePending,
    event: 'select_patient',
    isOHC: isOHCSide,
    isDental: appointmentInfo?.appointmentSource === 'AssisDent',
  })

  if (shouldDisplayDelegateView) {
    return (
      <DelegationUserSelect
        onSelectUser={onSelectUser}
        onBack={() => {
          setshouldDisplayDelegateView(false)
        }}
        disableUndefinedSelection={disableUndefinedSelection}
      />
    )
  }
  return (
    <MinMaxDiv size="620px">
      <Container>
        <Text as="h1" $size={500} $weight="Medium" $height="Medium">
          {t('component.userSelect.heading')}
        </Text>
        <Text as="p" $size={300} $weight="Regular" $height="Large">
          {!disableUndefinedSelection
            ? t('component.userSelect.paragraph')
            : t('component.userSelect.paragraphCallback')}
        </Text>
        <ButtonGroupContainer>
          <SelectUserOptionButton
            onClick={() => onReserveSelf()}
            text={t('component.reserveUserSelect.modal.self')}
          />
          <SelectUserOptionButton
            onClick={() => setshouldDisplayDelegateView(true)}
            text={t('component.userSelect.anotherPerson')}
          />
        </ButtonGroupContainer>
        {onBack !== undefined && (
          <DefaultButton variant="outlined" onClick={onBack}>
            {t('common.back')}
          </DefaultButton>
        )}
      </Container>
    </MinMaxDiv>
  )
}

export default UserSelect

import React from 'react'

import ServiceSelect from '../../../common/components/SearchSelect/SearchSelect'
import { useNodes } from '../../../common/hooks/useNode'
import { useModalEvents } from '../../../xstate/events'
import { useModalBreadcrumb } from '../../../xstate/selectors'

const SearchServiceSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { listedNodes, favoriteNodes } = useNodes()
  const { selectService, closeModal } = useModalEvents()
  const breadcrumb = useModalBreadcrumb()

  return (
    <ServiceSelect
      listedNodes={listedNodes}
      favoriteNodes={favoriteNodes}
      breadcrumb={breadcrumb}
      onSelect={selectService}
      onClose={closeModal}
    />
  )
}

export default SearchServiceSelect

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue, useRecoilState } from 'recoil'

import { ContractUsePeriods } from '../../__generated__/api'
import { EMPLOYMENT_START_THRESHOLD_DAYS } from '../../constants'
import { isOHCAtom } from '../../state/common/atoms'
import { defaultOhcSearchNodeId, defaultSelectedSearchNodeId } from '../../state/search/atoms'
import {
  OHCAllowance,
  loginStatusAtom,
  ohcAllowedStatusAtom,
  LoginStatus,
} from '../../state/user/atoms'
import { useSearchEvents } from '../../xstate/events'
import { SearchTargetValue } from '../../xstate/types'
import api from '../services/api'
import ohcService from '../services/ohc'

import { useApi2 } from './useApi'

export const useOHC = (): {
  isOHCSide: boolean
  ohcAllowedStatus: OHCAllowance | null
  namedCheckupOfficeCityId: number | null
  setOHC(ohc: boolean, pushHistory?: boolean): void
} => {
  const [isOHCSide, setIsOHC] = useRecoilState(isOHCAtom)
  const loginStatus = useRecoilValue(loginStatusAtom)
  const [ohcAllowedStatus, setOhcAllowedStatus] = useRecoilState(ohcAllowedStatusAtom)
  const allowedLoginStatus: LoginStatus[] = ['authenticated', 'impersonated']
  const shouldUpdateContractData =
    isOHCSide && allowedLoginStatus.includes(loginStatus) && ohcAllowedStatus === null

  const { data: contractUsePeriods, error: contractUsePeriodsError } = useApi2(
    api.v1.getContractDates,
    {},
    null,
    shouldUpdateContractData
  )

  const history = useHistory()
  const { setSearchTarget } = useSearchEvents()

  useEffect(() => {
    ohcService.setOHC(isOHCSide)
  }, [isOHCSide])

  useEffect(() => {
    if (contractUsePeriods !== null && ohcAllowedStatus === null) {
      setOhcAllowedStatus(isUserAllowedToUseOHC(contractUsePeriods))
    } else if (contractUsePeriodsError && ohcAllowedStatus === null) {
      setOhcAllowedStatus('disallowed')
    }
  }, [contractUsePeriods, ohcAllowedStatus, contractUsePeriodsError, setOhcAllowedStatus])

  const setOHC = (ohc: boolean, pushHistory = true) => {
    setIsOHC(ohc)
    ohcService.setOHC(ohc)
    setSearchTarget({
      id: ohc ? defaultOhcSearchNodeId : defaultSelectedSearchNodeId,
      value: SearchTargetValue.Node,
    })
    if (pushHistory) {
      history.push(ohc ? '/tyoterveys' : '/')
    }
  }

  return {
    isOHCSide,
    ohcAllowedStatus,
    namedCheckupOfficeCityId: contractUsePeriods?.namedCheckupOfficeCityId ?? null,
    setOHC,
  }
}

const isUserAllowedToUseOHC = (contractUsePeriods: ContractUsePeriods | null): OHCAllowance => {
  if (!contractUsePeriods) {
    return 'disallowed'
  }
  const hasDates = (contractUsePeriods.dates.length ?? 0) > 0
  if (!hasDates) {
    return 'disallowed'
  }
  const now = new Date()
  const allowedToUseContractNow = contractUsePeriods.dates.some((date) => {
    const startDate = new Date(date.startDate)
    const endDate = new Date(date.endDate ?? '9999-12-31T23:59:59.999Z')
    if (now >= startDate && now <= endDate) {
      return true
    }
  })

  if (contractUsePeriods.employmentStarts) {
    const thirtyDaysBeforeEmploymentStarts = new Date(contractUsePeriods.employmentStarts)
    thirtyDaysBeforeEmploymentStarts.setDate(
      thirtyDaysBeforeEmploymentStarts.getDate() - EMPLOYMENT_START_THRESHOLD_DAYS
    )
    if (now < thirtyDaysBeforeEmploymentStarts) {
      return 'disallowed'
    }
  }

  if (!allowedToUseContractNow) {
    return 'disallowed'
  }

  if (!contractUsePeriods.isOnlineReservationAllowed) {
    return 'online_reservation_denied'
  }

  return 'allowed'
}

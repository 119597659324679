import styled from '@emotion/styled'
import { Gray200, Gray300, Gray900, Primary300, Primary500 } from '@mehilainen/mds-customer/colors'
import { AngleRight, Tooth, Stethoscope, Briefcase, Headset } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useModalEvents } from '../../../xstate/events'
import { useIsMobile } from '../../hooks/useBreakpoint'
import useChangeLanguage from '../../hooks/useChangeLanguage'
import { getCustomerServiceNumber } from '../../services/customerService'
import * as Analytics from '../../utils/analytics'
import LangSelectButton from '../LangSelectButton/LangSelectButton'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import DesktopLogo from '../NavBar/desktopLogo.svg?react'
import { Text } from '../Typography/Typography'

interface NavLinkProps {
  text: string
  icon: JSX.Element
  dataCy?: string
  onClick?: () => void
}

const StyledButton = styled.button`
  display: flex;
  gap: 17px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid ${Gray300};
  font-size: 1rem;
  align-items: center;

  &:hover {
    background: ${Gray200};
  }

  .MuiSvgIcon-root {
    color: ${Primary500};
  }
`

const StyledAngleRight = styled(AngleRight)`
  margin-left: auto;
`

const NavLink: React.FC<React.PropsWithChildren<NavLinkProps>> = ({
  text,
  onClick,
  icon,
  dataCy,
}) => {
  return (
    <StyledButton onClick={onClick} data-cy={dataCy}>
      {icon}
      <Text $size={400} $weight="Regular" $height="Medium" $color={Gray900}>
        {text}
      </Text>
      <StyledAngleRight />
    </StyledButton>
  )
}

const TopContainer = styled.div`
  display: flex;
`

const LogoContainer = styled.div`
  margin-bottom: 30px;
`

const StyledLangSelectButton = styled(LangSelectButton)`
  margin-left: auto;
  margin-top: 3px;
`

const StyledH1 = styled.h1`
  font-size: 0.875rem;
  margin-bottom: 10px;
  margin-top: 10px;
`

const StyledH2 = styled.h2`
  font-size: 0.875rem;
  margin-bottom: 10px;
  margin-top: 30px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const StyledH3 = styled.h3`
  text-align: center;
  font-size: 0.875rem;
`

const StyledText = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  text-decoration: none;
  &:not(.no-link-styles) {
    &:link {
      text-decoration: none;
    }
  }
`

const LandingModal: React.FC<React.PropsWithChildren> = () => {
  const { changeLanguage: onLanguageSelect } = useChangeLanguage()
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link
  const { openCallbackModal, openInsuranceModal, closeModal } = useModalEvents()

  return (
    <ResponsiveModal open={true} fullWidth maxWidth="md">
      {isMobile ? (
        <>
          <TopContainer>
            <LogoContainer>
              <DesktopLogo />
            </LogoContainer>
            <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
          </TopContainer>
          <StyledH1>{t('component.landingModal.heading')}</StyledH1>
        </>
      ) : (
        <TopContainer>
          <StyledH1>{t('component.landingModal.heading')}</StyledH1>
          <StyledLangSelectButton onLanguageSelect={onLanguageSelect} />
        </TopContainer>
      )}

      <NavLink
        text={t('component.landingModal.privateLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.PRIVATE)
          closeModal()
        }}
        icon={<Stethoscope />}
        dataCy="landingModal-private"
      />
      <NavLink
        text={t('component.landingModal.insuranceLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.INSURANCE)
          openInsuranceModal()
        }}
        icon={<Stethoscope />}
      />
      <NavLink
        text={t('component.landingModal.dentalLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.DENTAL)
          window.location.assign(t('component.landingModal.dentalLink'))
        }}
        icon={<Tooth />}
      />
      <NavLink
        text={t('component.landingModal.occupationalLinkText')}
        onClick={() => {
          Analytics.trackServiceSelection(Analytics.ServiceSelectionType.OCCUPATIONAL)
          window.location.assign(t('component.landingModal.occupationalLink'))
        }}
        icon={<Briefcase />}
      />
      <StyledH2>{t('component.landingModal.callbackHeading')}</StyledH2>
      <NavLink
        text={t('component.landingModal.callbackLinkText')}
        onClick={openCallbackModal}
        icon={<Headset />}
      />
      <ContactContainer>
        <StyledH3>{t('component.landingModal.contactHeading')}</StyledH3>
        <StyledText as="a" $color={Primary300} target="_blank" href={link.link}>
          {telNumber}
        </StyledText>
        <StyledH3>{t('component.landingModal.customerServicePhonePrice')}</StyledH3>
      </ContactContainer>
    </ResponsiveModal>
  )
}

export default LandingModal

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ElectronicHealthRecordSystem,
  NodeServiceType,
  PractitionerDetails,
  PractitionerNode,
} from '../../../__generated__/api'
import AppointmentLengthSelect, {
  AppointmentLengthOption,
  CallbackOption,
} from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import FlexibleScheduleAppointmentLengthSelect, {
  GroupedFlexibleScheduleOption,
} from '../../../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'
import { DentalMaintenance } from '../../../common/components/DentalMaintenance/DentalMaintenance'
import Modal from '../../../common/components/Modal/Modal'
import PractitionerAppointmentInstructions from '../../../common/components/PractitionerAppointmentInstructions/PractitionerAppointmentInstructions'
import PractitionerHeader from '../../../common/components/PractitionerDetails/PractitionerHeader'
import { useFeatureFlags } from '../../../common/hooks/useFeatureFlags'
import { useOHC } from '../../../common/hooks/useOHC'
import { defaultSelectedStandardScheduleOption } from '../../../state/search/atoms'
import { useModalEvents } from '../../../xstate/events'
import {
  useSelectedFlexibleScheduleOption,
  useSelectedPractitioner,
  useSelectedStandardScheduleOptionFull,
} from '../../../xstate/selectors'
import { groupFlexibleScheduleOptions } from '../utils'

interface StandardSchedulePractitionerServiceSelectProps {
  practitionerDetails: PractitionerDetails
  selected: AppointmentLengthOption | undefined
  onClose(): void
  onSelect(option: AppointmentLengthOption | undefined): void
}

const StandardSchedulePractitionerServiceSelect: React.FC<
  React.PropsWithChildren<StandardSchedulePractitionerServiceSelectProps>
> = ({ practitionerDetails, selected, onClose, onSelect }) => {
  const { t } = useTranslation()
  const { isOHCSide } = useOHC()

  const defaultStandardLengthOption: AppointmentLengthOption = useMemo(
    () => ({ duration: defaultSelectedStandardScheduleOption, description: [] }),
    []
  )

  const featureFlags = useFeatureFlags()

  const standardLengthOptions: AppointmentLengthOption[] = useMemo(
    () => [
      defaultStandardLengthOption,
      ...practitionerDetails.appointmentLengthOptions,
      ...practitionerDetails.nodes
        .filter((node: PractitionerNode) =>
          node.serviceType === NodeServiceType.Ohc ? isOHCSide : true
        )
        .map((node: PractitionerNode) => ({
          duration: `node-${node.id}` as `node-${string}`,
          description: [],
          nodeName: node.name,
          nodeId: node.id,
        })),
      ...(practitionerDetails.allowsCallbackReservations && featureFlags?.callbackRequestsEnabled
        ? [CallbackOption]
        : []),
    ],
    [defaultStandardLengthOption, practitionerDetails, featureFlags, isOHCSide]
  )

  return (
    <Modal
      open={true}
      onClose={onClose}
      primaryButtonText={t(
        'component.practitionerAppointmentInstructions.showAvailableAppointments'
      )}
      onPrimaryButtonClick={onClose}
      primaryButtonDisabled={practitionerDetails.hasFlexibleSchedule && !selected}
      primaryButtonCy="appointmentLengthOptionSelect"
      secondaryButtonText={t('common.back')}
      onSecondaryButtonClick={onClose}
    >
      <PractitionerAppointmentInstructions
        practitionerDetails={practitionerDetails}
        lengthSelectComponent={
          <AppointmentLengthSelect
            options={standardLengthOptions}
            value={selected}
            onSelect={onSelect}
          />
        }
      />
    </Modal>
  )
}

interface FlexibleSchedulePractitionerServiceSelectProps {
  practitionerDetails: PractitionerDetails
  selected: GroupedFlexibleScheduleOption | undefined
  onClose(): void
  onSelect(option: GroupedFlexibleScheduleOption | undefined): void
}

const FlexibleSchedulePractitionerServiceSelect: React.FC<
  React.PropsWithChildren<FlexibleSchedulePractitionerServiceSelectProps>
> = ({ practitionerDetails, selected, onClose, onSelect }) => {
  const { t, i18n } = useTranslation()

  const flexibleLengthOptions = useMemo(
    () => groupFlexibleScheduleOptions(practitionerDetails, i18n.language),
    [practitionerDetails, i18n.language]
  )

  return (
    <Modal
      open={true}
      onClose={onClose}
      primaryButtonText={t(
        'component.practitionerAppointmentInstructions.showAvailableAppointments'
      )}
      onPrimaryButtonClick={onClose}
      primaryButtonDisabled={practitionerDetails.hasFlexibleSchedule && !selected}
      primaryButtonCy="appointmentLengthOptionSelect"
      secondaryButtonText={t('common.back')}
      onSecondaryButtonClick={onClose}
    >
      <PractitionerAppointmentInstructions
        practitionerDetails={practitionerDetails}
        lengthSelectComponent={
          <FlexibleScheduleAppointmentLengthSelect
            value={selected}
            onSelect={onSelect}
            options={flexibleLengthOptions}
          />
        }
      />
    </Modal>
  )
}

interface SearchAssisDentPractitionerServiceSelectProps {
  practitionerDetails: PractitionerDetails
  selected: AppointmentLengthOption | undefined
  onClose(): void
  onSelect(option: AppointmentLengthOption | undefined): void
}

const SearchAssisDentPractitionerServiceSelect: React.FC<
  React.PropsWithChildren<SearchAssisDentPractitionerServiceSelectProps>
> = ({ practitionerDetails, selected, onClose, onSelect }) => {
  const { t } = useTranslation()

  const options: AppointmentLengthOption[] = useMemo(() => {
    if (!practitionerDetails.assisDentReasons) {
      return []
    }
    return practitionerDetails.assisDentReasons.map((reason) => ({
      duration: `reason-${reason.id}` as `reason-${string}`,
      description: [],
      reasonId: reason.id,
      reasonName: reason.name,
    }))
  }, [practitionerDetails])

  const displayDentalMaintenance = practitionerDetails.isAssisDentMaintenance

  return (
    <Modal
      open={true}
      onClose={onClose}
      primaryButtonText={
        displayDentalMaintenance
          ? t('common.close')
          : t('component.practitionerAppointmentInstructions.showAvailableAppointments')
      }
      onPrimaryButtonClick={onClose}
      primaryButtonCy="appointmentLengthOptionSelect"
      secondaryButtonText={displayDentalMaintenance ? undefined : t('common.back')}
      onSecondaryButtonClick={onClose}
    >
      {displayDentalMaintenance ? (
        <>
          <PractitionerHeader
            practitioner={practitionerDetails}
            showDisplayAppointmentsButton={false}
          />
          <DentalMaintenance variant="nested" />
        </>
      ) : (
        <PractitionerAppointmentInstructions
          heading={t('component.searchPractitionerServiceSelect.assisDentSelectService')}
          practitionerDetails={practitionerDetails}
          lengthSelectComponent={
            <AppointmentLengthSelect options={options} value={selected} onSelect={onSelect} />
          }
        />
      )}
    </Modal>
  )
}

const PractitionerModal: React.FC<React.PropsWithChildren> = () => {
  const practitionerDetails = useSelectedPractitioner()
  const selectedStandardScheduleOption = useSelectedStandardScheduleOptionFull()
  const selectedFlexibleScheduleOption = useSelectedFlexibleScheduleOption()
  const { selectPractitionerOption, closeModal } = useModalEvents()

  if (!practitionerDetails) {
    return null
  }

  if (practitionerDetails.primarySource === ElectronicHealthRecordSystem.Dh) {
    if (practitionerDetails.hasFlexibleSchedule) {
      return (
        <FlexibleSchedulePractitionerServiceSelect
          practitionerDetails={practitionerDetails}
          selected={selectedFlexibleScheduleOption}
          onSelect={selectPractitionerOption}
          onClose={closeModal}
        />
      )
    }
    return (
      <StandardSchedulePractitionerServiceSelect
        practitionerDetails={practitionerDetails}
        selected={selectedStandardScheduleOption}
        onSelect={selectPractitionerOption}
        onClose={closeModal}
      />
    )
  } else {
    return (
      <SearchAssisDentPractitionerServiceSelect
        practitionerDetails={practitionerDetails}
        selected={selectedStandardScheduleOption}
        onSelect={selectPractitionerOption}
        onClose={closeModal}
      />
    )
  }
}

export default PractitionerModal

import React from 'react'

import CallbackModal from '../../common/components/CallbackModal/CallbackModal'
import LandingModal from '../../common/components/LandingModal/LandingModal'
import { useModalMachineState } from '../../xstate/selectors'
import PractitionerModal from '../search/components/PractitionerModal'
import SearchFilterModal from '../search/components/SearchFilterModal'
import ServiceSelectModal from '../search/components/ServiceSelectModal'

const Modals: React.FC = () => {
  const state = useModalMachineState()

  if (state.matches('LandingModal')) {
    return <LandingModal />
  }

  if (state.matches('CallbackModal')) {
    return <CallbackModal />
  }

  if (state.matches('PractitionerModal')) {
    return <PractitionerModal />
  }

  if (state.matches('ServiceModal')) {
    return <ServiceSelectModal />
  }

  if (state.matches('FilterModal')) {
    return <SearchFilterModal />
  }

  return null
}

export default Modals

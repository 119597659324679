import dayjs from 'dayjs'
import qs from 'qs'
import { fromPromise } from 'xstate'

import {
  AppointmentType,
  LanguageResult,
  PatientAgeGroup,
  PublicNode,
  SupportedLanguage,
  TimeRange,
} from '../__generated__/api'
import { FilterOptions, Gender } from '../common/components/FilterOptions/types'
import api from '../common/services/api'
import { AppointmentSearchMode, defaultSelectedSearchNodeId } from '../state/search/atoms'

import { ParsedURL } from './types'

export const parseURL = fromPromise(async (): Promise<ParsedURL> => {
  const pathname = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)

  const isOHC = pathname.includes('tyoterveys') || pathname.includes('tep')
  const lang =
    searchParams.has('lang') &&
    Object.values(SupportedLanguage).includes(searchParams.get('lang') as SupportedLanguage)
      ? (searchParams.get('lang') as SupportedLanguage)
      : SupportedLanguage.Fi

  let nodeData = {}
  if (searchParams.has('s')) {
    try {
      const res = await api.v1.searchNode({
        nodeId: searchParams.get('s') as string,
        lang,
        isOhc: isOHC,
      })
      const node = res.data[0]
      nodeData = {
        nodeId: node.id,
        node,
      }
    } catch {
      nodeData = { nodeId: defaultSelectedSearchNodeId }
    }
  }

  // FIXME:
  // - useNodeLinkings logic?

  const parsed = qs.parse(searchParams.toString(), { arrayLimit: 1000 })

  // Search filter options
  const filterOptions: Partial<FilterOptions> = {}

  if (parsed.ty) {
    filterOptions.appointmentTypes = parsed.ty as unknown as AppointmentType[]
  }
  if (parsed.tr) {
    filterOptions.timeRanges = parsed.tr as unknown as TimeRange[]
  }
  if (parsed.g) {
    filterOptions.gender = parsed.g as unknown as Gender
  }
  if (parsed.la) {
    filterOptions.language = parsed.la as unknown as LanguageResult
  }
  if (parsed.pag) {
    const _ageGroups = parsed.pag as unknown as PatientAgeGroup
    filterOptions.patientAgeGroup = { ..._ageGroups, id: Number(_ageGroups.id) }
  }

  return {
    isOHC,
    isDental: pathname.includes('hammashoito'),
    filterOptions,
    ...nodeData,
    ...(searchParams.has('p') ? { practitionerId: Number(searchParams.get('p')) } : {}),
    ...(searchParams.has('d') ? { date: dayjs(searchParams.get('d')) } : {}),
    ...(searchParams.has('mo')
      ? { appointmentSearchMode: searchParams.get('mo') as AppointmentSearchMode }
      : {}),
    ...(searchParams.has('mode')
      ? { appointmentSearchMode: searchParams.get('mode') as AppointmentSearchMode }
      : {}),
    ...(parsed.lo ? { location: parsed.lo as string[] } : {}),
    noLanding: searchParams.has('noLanding'),
    callbackOpen: searchParams.has('callbackOpen'),
  }
})

export const fetchNode = fromPromise(
  async ({
    input,
  }: {
    input: { lang: SupportedLanguage; nodeId: string }
  }): Promise<PublicNode> => {
    const res = await api.v1.searchNode(input)
    return res.data[0]
  }
)

export const resolveServiceSelection = fromPromise(
  async ({
    input,
  }: {
    input: { service: any /* FIXME */; lang: SupportedLanguage; isOHC: boolean }
  }) => {
    const { service, lang, isOHC } = input

    // Received breadcrumb
    if (Array.isArray(service)) {
      if (service.length === 0) {
        return undefined
      }
      return service[0].item
    }

    // Received node
    if (typeof service.id === 'string') {
      return service
    }

    // Autocomplete handlers
    if (service.practitionerData) {
      return { type: 'practitioner', id: service.practitionerData.id }
    }

    if (service.searchKeywordData) {
      const res = await api.v1.searchNode({
        keywordId: service.searchKeywordData.id,
        lang,
        isOhc: isOHC,
      })
      return res.data[0]
    }

    if (service.nodeData) {
      const res = await api.v1.searchNode({ nodeId: service.nodeData.id, lang, isOhc: isOHC })
      return res.data[0]
    }

    if (service.groupData) {
      const res = await api.v1.searchNode({ keyword: service.keyword, lang, isOhc: isOHC })
      return res.data[0]
    }

    return undefined
  }
)

export const fetchPractitionerDetails = fromPromise(
  async ({
    input,
  }: {
    input: { lang: SupportedLanguage; practitionerId: number; isOHC: boolean }
  }) => {
    const res = await api.v1.getPractitionerDetails(input)
    return res.data
  }
)

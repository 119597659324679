import {
  eHoitoEvent,
  eHoitoEventNavigation,
  eHoitoEventType,
} from '@mehilainen/omamehilainen-types/lib/ehoito'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { teliaJWTAtom } from '../../state/user/atoms'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { useOHC } from '../hooks/useOHC'

export const useEhoitoEvents = (): { events: eHoitoEvent[] | undefined; loading: boolean } => {
  const jwt = useRecoilValue(teliaJWTAtom)
  const { isOHCSide, ohcAllowedStatus } = useOHC()
  const [loading, setLoading] = useState<boolean>(false)
  const [events, setEvents] = useState<eHoitoEvent[] | undefined>()
  const featureFlags = useFeatureFlags()
  const [lastJwt, setLastJwt] = useState<string>()

  useEffect(() => {
    if (!isOHCSide || ohcAllowedStatus !== 'allowed' || !featureFlags?.omlightEnabled) {
      setEvents(undefined)
      setLoading(false)
      return
    }

    if (jwt && !loading && jwt !== lastJwt) {
      setLoading(true)
      setLastJwt(jwt)
      const codes = ['TEP_API_IKARYHMA', 'TEP_API_MAARAIKA', 'TEP_API_MUUTTARK', 'TEP_API_TYOHTULO']
      Promise.all(
        codes.map((code) =>
          fetch(`${process.env.REACT_APP_OMAMEHILAINEN_API}/rest/v1/users/front-page-json`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: jwt },
            body: JSON.stringify({
              type: ['ehoito'],
              code,
            }),
          }).then((res) => res.json())
        )
      )
        .then((results) => {
          const fetchedEvents = results.flat() as eHoitoEvent[]
          const filteredEvents = fetchedEvents.filter((event) => {
            return event.visibility ? !event.visibility.isHidden : true
          })
          setEvents(filteredEvents)
          setLoading(false)
        })
        .catch(() => {
          setEvents([])
          setLoading(false)
        })
    }
  }, [jwt, lastJwt, loading, events, isOHCSide, ohcAllowedStatus, featureFlags])

  return { events, loading }
}

export const useEhoitoNavigationEvents = (): {
  events: eHoitoEventNavigation[] | undefined
  loading: boolean
} => {
  const { events, loading } = useEhoitoEvents()
  const navigationEvents = useMemo(
    () => events?.filter((event) => event.type === eHoitoEventType.NAVIGATION),
    [events]
  )
  return { events: navigationEvents as eHoitoEventNavigation[] | undefined, loading }
}

import { PollH, SignOutAlt } from '@mehilainen/mds-customer/icons'
import { Badge } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'

import useUserState from '../../../domain/reserve/hooks/useUserState'
import { omLightModalAtom } from '../../../state/common/atoms'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import useLoginState from '../../hooks/useLoginState'
import { useOHC } from '../../hooks/useOHC'
import { useEhoitoNavigationEvents } from '../../services/omApi'
import NoEvents from '../OMLight/NoEvents'

import { UserMenu } from './UserMenu'

const UserButton: React.FC = () => {
  const { i18n } = useTranslation()
  const { isOHCSide, ohcAllowedStatus } = useOHC()
  const setOmLightModal = useSetRecoilState(omLightModalAtom)
  const { t } = useTranslation()
  const { events } = useEhoitoNavigationEvents()
  const { loginStatus, logout } = useLoginState()
  const {
    userState: { user, pending: userPending },
  } = useUserState()
  const featureFlags = useFeatureFlags()
  const isOHC = isOHCSide && ohcAllowedStatus === 'allowed'
  const hasEvents = isOHC && events && events.length > 0

  if (loginStatus !== 'authenticated' || userPending) {
    return null
  }

  return (
    <Badge badgeContent={hasEvents ? events.length : undefined} color="error">
      <UserMenu
        name={user?.firstName}
        topComponent={
          hasEvents ? undefined : isOHC && featureFlags?.omlightEnabled ? <NoEvents /> : undefined
        }
        events={
          events
            ? events.map((event) => ({
                startIcon: <PollH />,
                title: event.i18n?.name[i18n.language],
                description: event.i18n?.description[i18n.language],
                onClick: () => {
                  setOmLightModal(event.carePlanGuid)
                },
              }))
            : undefined
        }
        links={[
          {
            children: t('component.userButton.list.healthInformation'),
            component: 'a',
            href: `${process.env.REACT_APP_OMAMEHILAINEN_URL}/om-app/`,
            className: 'no-external-icon no-link-styles',
          },
        ]}
        ButtonProps={{
          children: t('component.userButton.logout'),
          variant: 'outlined',
          size: 'large',
          startIcon: <SignOutAlt />,
          onClick: () => {
            logout()
          },
        }}
      />
    </Badge>
  )
}

export default UserButton

import styled from '@emotion/styled'
import { White } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentSearchResult,
  ContractRuleStatus,
  Modal,
  ModalAction,
  PublicNode,
} from '../../../__generated__/api'
import {
  DefaultAnchorButton,
  DefaultButton,
} from '../../../common/components/DefaultButton/DefaultButton'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import ModalHeader from '../../../common/components/Modal/ModalHeader/ModalHeader'
import ResponsiveModal from '../../../common/components/Modal/ResponsiveModal/ResponsiveModal'
import { Text } from '../../../common/components/Typography/Typography'
import { useSearchEvents } from '../../../xstate/events'
import { SearchTargetValue } from '../../../xstate/types'

// Styles

const ModalContentContainer = styled(ColumnFlex)`
  background: ${White};
  height: 100%;
`

const ModalHeadingContainer = styled(ColumnFlex)`
  gap: 16px;
  margin-bottom: 24px;
  text-align: center;
`

const ModalActionContainer = styled(ColumnFlex)`
  gap: 10px;
  align-items: center;
`

// Types

// TODO: Custom should nowdays be the only type needed, remove the rest & code using them
export enum AppointmentNoteType {
  HELTH_TEAM = 'HELTH_TEAM',
  REFERRAL = 'REFERRAL',
  RESTRICTIONS = 'RESTRICTIONS',
  DISALLOWED = 'DISALLOWED',
  CUSTOM = 'CUSTOM',
}

export interface AppointmentNoteConfig {
  onContinue(): void
  onClose(): void
  onDigitalClinic(queue: string): void
  type: AppointmentNoteType
  modal?: Modal
}

interface Props {
  note: AppointmentNoteConfig | null
}

interface ModalWrapperProps extends Props {
  heading: string
  subHeading: string
}

// Components

const ModalWrapper: React.FC<React.PropsWithChildren<ModalWrapperProps>> = ({
  children,
  note,
  heading,
  subHeading,
}) => {
  return (
    <ResponsiveModal onClose={note?.onClose} fullWidth maxWidth="md" open={Boolean(note)}>
      <ModalContentContainer>
        <ModalHeader open={Boolean(note)} onClose={note?.onClose} />
        <ModalHeadingContainer>
          <Text as="h1" $size={500} $weight="Medium" $height="Medium">
            {heading}
          </Text>
          <Text>{subHeading}</Text>
        </ModalHeadingContainer>
        <ModalActionContainer>{children}</ModalActionContainer>
      </ModalContentContainer>
    </ResponsiveModal>
  )
}

const AppointmentNoteModal: React.FC<React.PropsWithChildren<Props>> = ({ note }) => {
  const { t } = useTranslation()
  const { setSearchTarget } = useSearchEvents()

  const sortedActions: ModalAction[] = note?.modal?.actions
    ? [...note?.modal?.actions].sort((a, b) => {
        if (a.appearance === 'primary') {
          return -1
        } else if (b.appearance === 'primary') {
          return 1
        } else if (a.appearance === 'secondary') {
          return -1
        } else if (b.appearance === 'secondary') {
          return 1
        } else {
          return 0
        }
      })
    : []

  switch (note?.type) {
    case AppointmentNoteType.CUSTOM:
      return (
        <ModalWrapper
          note={note}
          heading={note.modal?.title ?? ''}
          subHeading={note.modal?.content ?? ''}
        >
          {sortedActions.map((action) => {
            const variant =
              action.appearance === 'secondary'
                ? 'outlined'
                : action.appearance === 'tertiary'
                ? 'text'
                : undefined
            return (
              <React.Fragment key={action.id}>
                {action.type !== 'url' ? (
                  <DefaultButton
                    variant={variant}
                    onClick={
                      action.type === 'close'
                        ? note.onClose
                        : action.type === 'continue'
                        ? note.onContinue
                        : action.type === 'digital_clinic'
                        ? () => note.onDigitalClinic(action.queue ?? '')
                        : action.type === 'node'
                        ? () => {
                            setSearchTarget({
                              id: action.node!,
                              value: SearchTargetValue.Node,
                            })
                            note?.onClose()
                          }
                        : () => false
                    }
                  >
                    {action.title}
                  </DefaultButton>
                ) : (
                  <DefaultAnchorButton
                    fullWidth
                    variant={variant}
                    href={action.url ?? ''}
                    className="no-link-styles"
                  >
                    {action.title}
                  </DefaultAnchorButton>
                )}
              </React.Fragment>
            )
          })}
        </ModalWrapper>
      )
    case AppointmentNoteType.HELTH_TEAM:
      return (
        <ModalWrapper
          note={note}
          heading={t('component.ohcNote.healthTeam.heading')}
          subHeading={t('component.ohcNote.healthTeam.subHeading')}
        >
          <DefaultButton onClick={note?.onContinue}>
            {t('component.ohcNote.healthTeam.continue')}
          </DefaultButton>
          <DefaultButton variant="outlined">Varmista dta</DefaultButton>
          <DefaultButton variant="text" onClick={note?.onClose}>
            {t('common.cancel')}
          </DefaultButton>
        </ModalWrapper>
      )
    case AppointmentNoteType.REFERRAL:
      return (
        <ModalWrapper
          note={note}
          heading={t('component.ohcNote.referral.heading')}
          subHeading={t('component.ohcNote.referral.subHeading')}
        >
          <DefaultButton onClick={note?.onContinue}>
            {t('component.ohcNote.referral.continue')}
          </DefaultButton>
          <DefaultButton variant="text" onClick={note?.onClose}>
            {t('common.cancel')}
          </DefaultButton>
        </ModalWrapper>
      )
    case AppointmentNoteType.RESTRICTIONS:
      return (
        <ModalWrapper
          note={note}
          heading={t('component.ohcNote.restrictions.heading')}
          subHeading={t('component.ohcNote.restrictions.subHeading')}
        >
          <DefaultButton onClick={note?.onContinue}>
            {t('component.ohcNote.restrictions.continue')}
          </DefaultButton>
          <DefaultButton variant="outlined">Varmista dta</DefaultButton>
          <DefaultButton variant="text" onClick={note?.onClose}>
            {t('common.cancel')}
          </DefaultButton>
        </ModalWrapper>
      )
    case AppointmentNoteType.DISALLOWED:
      return (
        <ModalWrapper
          note={note}
          heading={t('component.ohcNote.nonOhc.heading')}
          subHeading={t('component.ohcNote.nonOhc.subHeading')}
        >
          <DefaultButton onClick={note?.onContinue}>
            {t('component.ohcNote.nonOhc.continue')}
          </DefaultButton>
          <DefaultButton variant="text" onClick={note?.onClose}>
            {t('common.cancel')}
          </DefaultButton>
        </ModalWrapper>
      )
    default:
      return null
  }
}

// Utils

export const getAppointmentNoteConfig = (
  appointment: AppointmentSearchResult,
  node: PublicNode,
  isOhc: boolean,
  modals: Modal[] | null
): AppointmentNoteConfig | null => {
  if (appointment.modalId) {
    const modal = modals?.find((m) => m.id === appointment.modalId)
    if (modal) {
      return {
        onContinue: () => false,
        onClose: () => false,
        onDigitalClinic: () => false,
        type: AppointmentNoteType.CUSTOM,
        modal,
      }
    }
  } else if (!isOhc) {
    return null
  }

  if (
    node.connections?.some(
      (connection) =>
        connection.practitionerRestriction === 'named_practitioners' && connection.priority > 1
    ) &&
    !appointment.primarySpecialistRole
  ) {
    return {
      onContinue: () => false,
      onClose: () => false,
      onDigitalClinic: () => false,
      type: AppointmentNoteType.HELTH_TEAM,
    }
  }

  if (appointment.isCoveredByOHC === ContractRuleStatus.Restriction) {
    return {
      onContinue: () => false,
      onClose: () => false,
      onDigitalClinic: () => false,
      type: AppointmentNoteType.RESTRICTIONS,
    }
  }

  if (appointment.isCoveredByOHC === ContractRuleStatus.Disallowed) {
    return {
      onContinue: () => false,
      onClose: () => false,
      onDigitalClinic: () => false,
      type: AppointmentNoteType.DISALLOWED,
    }
  }

  if (appointment.isCoveredByOHC === ContractRuleStatus.ReferralRequired) {
    return {
      onContinue: () => false,
      onClose: () => false,
      onDigitalClinic: () => false,
      type: AppointmentNoteType.REFERRAL,
    }
  }

  return null
}

export default AppointmentNoteModal

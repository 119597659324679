class OHCService {
  private ohc: boolean = false

  public setOHC(ohc: boolean): void {
    this.ohc = ohc
  }

  public isOHC(): boolean {
    return this.ohc
  }
}

const ohcService = new OHCService()

export default ohcService

// FIXME: Move rest of parsing logic to xstate (insurance).
// TODO: Move state persistence to url to xstate?
import equal from 'fast-deep-equal'
import * as qs from 'qs'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import { SupportedLanguage } from '../../../__generated__/api'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import { externalUrlParameters } from '../../../common/utils/url'
import { InsurancePaymentType, selectedInsuranceSelector } from '../../../state/common/selectors'
import {
  defaultAppointmentSearchMode,
  defaultSelectedAppointmentListVariant,
  defaultSelectedAppointmentTypes,
  defaultSelectedDate,
  defaultSelectedGender,
  defaultSelectedLanguage,
  defaultSelectedLocation,
  defaultSelectedPatientAgeGroup,
  defaultSelectedTimeRanges,
  defaultSelectedStandardScheduleOption,
  selectedAppointmentListVariantAtom,
} from '../../../state/search/atoms'
import { useURLEvents } from '../../../xstate/events'
import {
  useAppointmentSearchMode,
  useDefaultNode,
  useIsUserSelectedDate,
  useIsUserSelectedNode,
  useSearchFilterOptions,
  useSearchLocation,
  useSearchTarget,
  useSelectedDate,
  useSelectedStandardScheduleOption,
} from '../../../xstate/selectors'
import { SearchTargetValue } from '../../../xstate/types'

export const locationParamKey = 'lo'

const useSearchHistory = (): void => {
  const history = useHistory()
  const searchTarget = useSearchTarget()
  const searchFilters = useSearchFilterOptions()
  const selectedDate = useSelectedDate()
  const selectedLocation = useSearchLocation()
  const selectedAppointmentListVariant = useRecoilValue(selectedAppointmentListVariantAtom)
  const [selectedInsurance, setSelectedInsurance] = useRecoilState(selectedInsuranceSelector)
  const appointmentSearchMode = useAppointmentSearchMode()
  const selectedStandardScheduleOption = useSelectedStandardScheduleOption()
  const isUserSelectedDate = useIsUserSelectedDate()
  const isUserSelectedNode = useIsUserSelectedNode()
  const defaultNode = useDefaultNode()
  const { currentLanguage } = useChangeLanguage()
  const { setSearchParams } = useURLEvents()
  const whiteListedSearch = useRef<qs.ParsedQs | null>(null)

  useEffect(() => {
    const searchString = history.location.search.slice(1)
    if (searchString !== '') {
      const parsed = qs.parse(searchString, { arrayLimit: 1000 })
      whiteListedSearch.current = getWhitelistedSearch(parsed)
      parsed.in !== undefined &&
        setSelectedInsurance(
          parsed.in.length
            ? {
                id: parsed.in === 'other' ? 'other' : Number(parsed.in),
                paymentType: parsed.inpt as InsurancePaymentType,
              }
            : undefined
        )
      if (parsed.sourceService) {
        sessionStorage.setItem('av3_sourceService', String(parsed.sourceService))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Serialize relevant state to url query string
  useEffect(() => {
    const search = new URLSearchParams(
      qs.stringify({
        ...Object.assign(
          {},
          whiteListedSearch.current ?? {},
          ...[
            [selectedLocation, defaultSelectedLocation, locationParamKey],
            [searchFilters.gender, defaultSelectedGender, 'g'],
            [searchFilters.timeRanges, defaultSelectedTimeRanges, 'tr'],
            [searchFilters.appointmentTypes, defaultSelectedAppointmentTypes, 'ty'],
            [selectedAppointmentListVariant, defaultSelectedAppointmentListVariant, 'lv'],
            [searchFilters.patientAgeGroup, defaultSelectedPatientAgeGroup, 'ag'],
            searchTarget.value === SearchTargetValue.Practitioner
              ? [searchTarget.id, undefined, 'p']
              : [searchTarget.id, defaultNode, 's', isUserSelectedNode],
            [currentLanguage, SupportedLanguage.Fi, 'lang'],
            [searchFilters.language, defaultSelectedLanguage, 'la'],
            [appointmentSearchMode, defaultAppointmentSearchMode, 'mode'],
            [selectedStandardScheduleOption, defaultSelectedStandardScheduleOption, 'so'],
          ].map(([value, defaultValue, key, specialRule]) => ({
            ...(!equal(value, defaultValue) || specialRule ? { [key as string]: value } : {}),
          }))
        ),
        ...(!selectedDate.isSame(defaultSelectedDate, 'day') && isUserSelectedDate
          ? { d: selectedDate.toISOString() }
          : {}),
        ...(selectedInsurance
          ? { in: selectedInsurance.id, inpt: selectedInsurance.paymentType }
          : {}),
      })
    )
    setSearchParams(search)
  }, [
    history,
    searchTarget,
    searchFilters,
    selectedDate,
    selectedLocation,
    selectedAppointmentListVariant,
    appointmentSearchMode,
    selectedStandardScheduleOption,
    selectedInsurance,
    isUserSelectedDate,
    isUserSelectedNode,
    currentLanguage,
    defaultNode,
    setSearchParams,
  ])
}

// Retain these GET parameters for marketing
const whitelist = [
  'gclid',
  'dclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_id',
]

const getWhitelistedSearch = (parsed: qs.ParsedQs): qs.ParsedQs => {
  const emptyObj: qs.ParsedQs = {}
  const whitelisted = whitelist.concat(externalUrlParameters.map((param) => param.toLowerCase()))
  for (const key in parsed) {
    if (whitelisted.includes(key.toLowerCase())) {
      emptyObj[key] = parsed[key]
    }
  }

  return emptyObj
}

export default useSearchHistory
